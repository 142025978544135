import { useForm } from "react-hook-form";

// export const { register, handleSubmit, watch, errors,formState ,reset } = useForm({
//     mode: "onChange",
//     defaultValues: selectedItem
// });


export const FormUtil = (config)=>  (useForm({
    mode: config && config.mode ? config.mode: "onChange",
    defaultValues: config && config.defaultValues ? config.defaultValues: {}
}));
