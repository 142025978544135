

import PouchDB from 'pouchdb';
import pouchdbfind from 'pouchdb-find';
PouchDB.plugin(pouchdbfind)
 const db =new PouchDB('pos-system');



 export const saveToDb=(document,data)=>{

    data.document = document
    if(data._id && data._rev){// yes id is the unique key generated by persistance system !!
      return db.put(data);
    }
    return db.post(data);

 }



 export const fetchAll=(document)=>{
   //need to put the filter
   console.log(document)

   return db.find({
    selector: {'document': document},
    // fields: ['document', document],
    // sort: ['name'],
    limit:25,
    }).then(row => {
      const data =  row.docs.map((doc) => {
       doc.id = doc._id;
       return doc;
      } 
    ) 
    return data;  
  }).catch(function (err) {
    console.log(err);
  });
}
 


 export const removeDoc=(document,data)=>{

  return db.remove(data);
}
 