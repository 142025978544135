import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Grid as CategoryGrid } from "../category/CategoryGrid";
import { CategoryActions } from "../category/state/AdminActions";
import { ItemGrid } from "../items/ItemGrid";
import { ShoppingCart } from "../sales-line-list/ShoppingCart";
import { actions as CartActions } from "./cart";


const PosView = () => {


    const dispatch = useDispatch()
    const [item, setitem] = useState();
    const [category, setCategory] = useState();
    // const posview=   useSelector(state=> state.cart);

    const onItemSelect = (data) => {
        // selectItem(data);
        // setitem({...data})
        console.log(item)
        //actions>addToCart(data)
        dispatch(CartActions.addItemToCart(data));
    }
    const onCategorySelect = (data) => {
        setCategory({ ...data })
        //load item
        dispatch(CategoryActions.fetchItems("items", { "field": "product.category.id", "field-value": data.id }))
    }

    const onSelectLine = (data) => {
        console.log(data)
        //get the saleslinelist and selected item 
        // this.setState({...this.state,selectedLine:data});

    }
    const onRemoveItem = (index, line) => {
        console.log(line)
        this.props.removeItem(line);
    }


    useEffect(() => {
        dispatch(CategoryActions.fetchItems("categories"))
    }, []);


    const itemSelector = () => {
        return <div className="col-md-8">
            <div className="card-box">
                <div className="row">
                    <div className="col-md-4">
                        <input type="text" id="search" className="form-control" placeholder="Search product by name or sku" />
                    </div>
                    <div className="col-md-8">
                        <div className="" id="categories">
                            <CategoryGrid onSelect={onCategorySelect} domain="categories"></CategoryGrid>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row" id="parent">
                    <ItemGrid onSelect={onItemSelect}></ItemGrid>
                </div>

            </div>
        </div>;
    }

    return (<div id="pos_view">
        <Grid container>
            {/* user info */}
            <Grid item lg={5} xs={1}>
                {itemSelector()}
            </Grid>
            <Grid item lg={7}>
                <ShoppingCart onSelectLine={onSelectLine} onRemoveItem={onRemoveItem} item={item} />
            </Grid>
        </Grid>
    </div>
    )




}
export default PosView;
