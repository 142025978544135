
import React from 'react';
import { useFormContext } from 'react-hook-form';

export default function Select({ options, attr,name, allowEmpty, validation,defaultValue, ...rest }) {
    const { register } = useFormContext();
    console.log(defaultValue)

    return (
        <select {...register(name)} name={name} {...rest} defaultValue>

            {allowEmpty && <option value=''>Select a value</option>}
            {options && options.map((value) => (
                (attr && <option value={value[attr]}>{value[attr]}</option>) ||
                (<option value={value}>{value}</option>) 

            ))}
        </select>
    );
}