import React from 'react';
import { Table } from '../component/Table';
import { CategoryList } from './CategoryList';
import { CategoryDetail} from './CategoryDetail';
import Icon from '@mui/material/Icon';

export const CategoryUI =()=>{

    const domain="categories";
    
 const sampleForm= ({handleSubmit,register,isDirty,customReset,onSubmit,errors,isValid,newEntry})=>
 <div className="detail">
 <form onSubmit={handleSubmit(onSubmit)}>
           {/* <label htmlFor="id">ID:</label> */}
   {/* <input name="id"  ref={register} /> */}
   <label htmlFor="code">Code :</label>
   <input name="code"  ref={register({ required: true })} />
   <label htmlFor="description">Description :</label>
   <input name="description" ref={register} />
   {errors.code && <span>This field is required</span>}
   <input type="submit"  disabled={!(isDirty && isValid)}/>
   <input type="button" value="Reset" onClick={customReset} />
   <input type="button" value="New" onClick={newEntry} />
   {/* <pre>{JSON.stringify(formState, null, 2)}</pre> */}
   
 </form>
 </div>

const header = [{"attr":"id","description":"ID"},{"attr":"code","description":"Code"},{"attr":"description","description":"Description"}];
 const Test =(props)=> <div><span>test--{props.om}--</span></div>
//  formDetail={<Test om={domain}></Test>}
    return <div><span>Categories</span>     
            <CategoryDetail   domain={domain}></CategoryDetail>
            <CategoryList domain={domain} header={header}></CategoryList>
        </div>

} 
