const dev = {
    "api": {

        "rooturl": "http://localhost:1337",
        "categories":
        {
            "fetchItemsURL": "http://localhost:8082/api/v1/categories/",
            "postItemsURL": "http://localhost:8082/api/v1/categories/"
        },
        "items":
        {
            "fetchItemsURL": "http://localhost:8082/api/v1/skus/",
            "postItemsURL": "http://localhost:1337/items/"
        },
        "user": {
            "login": "http://localhost:8087/public/users/v1/login"
        }
    }
}

const config = process.env.stage === 'prod' ? dev : dev


dev.fetchItemsURL = dev.api.items.fetchItemsURL;
dev.postItemsURL = dev.api.items.fetchItemsURL;
dev.deleteItemsURL = dev.api.items.fetchItemsURL;
dev.login = dev.api.user.login;
dev.isoffline = false;


export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};