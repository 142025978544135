import React, { useEffect, useState } from "react";
import { DetailForm } from "../component/DetailForm";
import { Input, Select } from "../component";
// import { Select } from "../component/Select";
import "./CategoryList.css";
import { Get } from "./state/AdminActions";


const vals = {
  required: "true",
  minLength: {
    value: 5,
    message: "min length is 5"
  }
}

const categoryForm = {
  feilds: [
    { name: 'code', label: 'Code', validation: vals },
    { name: 'name', label: 'name', validation: { required: true } },
    { name: 'description', label: 'description', validation: { required: true } },
    { name: 'description', label: 'description', validation: { required: true }, type: 'textarea' },
    { name: 'catGroup', label: 'Group', validation: { required: true }, type: 'select', options: ['a', 'b', 'c'] },
  ]

}
const dev ={code:'test2',name:'name',description:'descriptionf',catGroup:'1'}

export const CategoryDetail = () => {

  const [items, setItems] = useState(['one','two']);

  useEffect(() => {
    Get('http://localhost:8082/api/v1/categories/',null).then(res => {
      setItems(res.data);
    })
  }, []);

  return (<>
    <DetailForm formConfig={categoryForm} defaultValues={dev}>
      <Input name="code" validation={vals} label='Code' defaultValue='test'/>
      <Input name="name" />
      <Input name="description" />
      <Select name="catGroup"  options={items}  allowEmpty />
      {/* <button>Submit</button> */}
    </DetailForm>

  </>)
}
