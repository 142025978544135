import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const SKUsApi = createApi({
    reducerPath: 'SKUsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'http://localhost:8082/api/v1/',
    }),
    tagTypes: ['Skus'],
    endpoints: (build) => ({
        getSkus: build.query({
            query: () => ({ url: `skus/` }),
            transformResponse: (response, meta, arg) => response.content,
        }),
    }),
})


export const {  useGetSkusQuery } = SKUsApi