import React, { Component, Fragment } from "react";
import  MTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export  const Table =({data,headers,listkey,onSelectItem ,onDeleteItem})=>{

    const onItemClicked = (e,id)=>{
        console.log('onItemClicked'+ JSON.stringify(id))
        onSelectItem(id)
    }


    const onEdit = (e,id)=>{
        e.stopPropagation();
        // e.nativeEvent.preventDefault()
        console.log('onEdit'+id)
    }
    
    const onDelete = (e,item)=>{
        e.preventDefault()
        e.stopPropagation()
        onDeleteItem(item)
        console.log('onDelete'+item)
    }

    function getNestedValue(obj, key) {
        return key.split(".").reduce(function(result, key) {
          if(key && result){
           return result[key]
          } 
          else{
            return "";
          } 
        }, obj);
    }

    const materialTable = <TableContainer component={Paper}>
    <MTable  aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
                    {headers.map((header, i)=>        
                      <TableCell key={i}>{header.description}</TableCell>
                    )}
                    <TableCell>tools</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { data && data.map((line,index) => (
          <TableRow key={line.name}  onClick={e=> onItemClicked(e,line)} key={ listkey ? line[listkey] : index.toString()}>
            <TableCell  > {index.toString()}
                       </TableCell>
            {headers.map((header,i)=> <TableCell  key={i} >{getNestedValue(line,header.attr)}</TableCell> )}
          
            <TableCell align="right">{line.protein}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MTable>
  </TableContainer>

return materialTable;
}