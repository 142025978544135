import React, { useState } from 'react';
import config from '../config';
import { FormUtil } from '../form/FormsUtil';


export const Login = ({onSubmit}) => {

    const [logedIn, setlogedIn] = useState();
    
    const test = (data)=>{
        console.log(data);
        console.log(config.login)

        fetch(config.login,{
            method: 'POST',
            body:JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
              },
          })
      .then(res => res.json())
      .then(json => setlogedIn({ data: json }));

    }
    
    const { register, handleSubmit, watch, errors,formState ,reset } = FormUtil();

    return <div className="d-flex justify-content-center h-100">
        <div className="card">
            <div className="card-header">
                <h3>Sign In</h3>
                <div className="d-flex justify-content-end social_icon">
                    <span><i className="fab fa-facebook-square"></i></span>
                    <span><i className="fab fa-google-plus-square"></i></span>
                    <span><i className="fab fa-twitter-square"></i></span>
                </div>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(test)}>
                    <div className="input-group form-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fas fa-user"></i></span>
                        </div>
                        <input type="text" name="username" className="form-control" placeholder="username" ref={register}/>

                    </div>
                    <div className="input-group form-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fas fa-key"></i></span>
                        </div>
                        <input type="password" name="password" className="form-control" placeholder="password" ref={register} />
                    </div>
                    <div className="row align-items-center remember">
                        <input type="checkbox" ref={register} />Remember Me
                  </div>
                    <div className="form-group">
                        <input type="submit" value="Login"  name="rememberme" className="btn float-right login_btn" ref={register}/>
                    </div>
                </form>
            </div>
            <div className="card-footer">
                <div className="d-flex justify-content-center links">
                    Don't have an account?<a href="#">Sign Up</a>
                </div>
                <div className="d-flex justify-content-center">
                    <a href="#">Forgot your password?</a>
                </div>
            </div>
        </div>
    </div>

};
