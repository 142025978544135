import TF from '@mui/material/TextField';
import React, { forwardRef } from 'react';
// import { Controller, useFormContext, useController, useForm } from 'react-hook-form';


// function TextField({ name, validations }) {
//   const { errors, isValid } = useFormContext();
//   console.log(errors)
//   const {
//     field: { ref, ...inputProps },
//     meta: { invalid, isTouched, isDirty },
//   } = useController({
//     name,
//     rules: validations,
//     defaultValue: "",
//   });

//   return <TF {...inputProps} inputRef={ref} />;
// }

export function TextField({ register, name, ...rest }) {
  return <input {...register(name)} {...rest} />;
}


export function Input({ register, name, ...rest }) {
  return <input {...register(name)} {...rest} />;
}

export default TextField;