import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

export default function Input({ name, validation,defaultValue, ...rest }) {
    const methods = useFormContext();
    const { register } = methods ;
    const { isDirty, errors, isValid } = methods.formState;

    return (
        <div className="inputContainer">
            <label>{rest.label}</label><br />
            {/* <input ref={register(validations)} id={id} name={name} type={type || 'text'} /> */}
            <input {...register(name, validation)} name={name} defaultValue {...rest} />
            {!isValid && <span>{errors && errors[name] && errors[name].message}</span>}
        </div>
    );
}


// https://codesandbox.io/s/provider-perf-forked-r24ho?file=/src/App.js:587-598
// https://react-hook-form.com/advanced-usage#SmartFormComponent

