import { Card, CardContent } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useGetCategoriesQuery } from "./state/CategoryApiCall";

export const Grid = ({ onSelect, domain }) => {

  const dispatch = useDispatch()
  const { data, error, isLoading } = useGetCategoriesQuery()
  const itemSearch = (e) => {

  }
  const onItemClicked = (e, data) => {
    onSelect(data)
  }

  let listItems = [];
  if (data && Array.isArray(data)) {
    listItems = data.map((data, index) =>
      <div onClick={e => onItemClicked(e, data)} className="item-list-item" key={index.toString()}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <div style={{ overflow: 'hidden' }}>{data.id}</div>
            <div>{data.description}</div>
          </CardContent>
        </Card>



      </div>);
  };

  return <div>
    <div> {domain} search
      <input onChange={(e) => itemSearch(e.target.value)} type="text" />
      <button onClick={(e) => itemSearch(e)}>search</button>
    </div>
    {/* <div>last selected {state.selected.id}</div> */}
    <div className="item-list">{listItems}</div>

  </div>

}