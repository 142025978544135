import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const CategoriesApi = createApi({
    reducerPath: 'CategoriesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'http://localhost:8082/api/v1/',
    }),
    tagTypes: ['Categories'],
    endpoints: (build) => ({
        getCategories: build.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: () => ({ url: `categories/` }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response.content,
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response, meta, arg) => response.status
        }),
    }),
})


export const { useGetCategoriesQuery } = CategoriesApi