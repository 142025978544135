import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '../component/Table';
import { deleteItem, fetchItems,selectItem } from './state/AdminActions';
import { extractDomainSelect } from './state/util';


export const CategoryList =({domain,header})=>{    

    // const domain = "categories";
    const dispatch = useDispatch()

    
    const onSelectItem=(item)=>{
        dispatch(selectItem(domain,item))                    
    }
    const onDeleteItem=(item)=>{
        dispatch(deleteItem(domain,item))      //after the delete there should be post action 
        dispatch(selectItem(domain,{}))                    

    }

    useEffect(() => {
        dispatch(fetchItems(domain))
       },[]);

       const data = useSelector((state)=> extractDomainSelect(state,domain).listFetch) 

     const onEdit=(e)=>{
        dispatch(fetchItems(domain))

     }  

    return <div><span>Categories</span>      
                    {/* <button onClick={e=> onEdit(e)}>New</button> */}
                    
        <Table data={data} headers={header} listkey="id" onSelectItem={onSelectItem} onDeleteItem={onDeleteItem}></Table>
    </div>
}


// const data = [{"id":"test","desc":"test -desc"},{"id":"test1","desc":"test1 -desc"},{"id":"test3","desc":"test33 -desc"}]
// const header = [{"attr":"id","description":"ID"},{"attr":"code","description":"Code"},{"attr":"description","description":"Description"}];
