
import React, { useState, useEffect } from 'react';
import { CategoryDetail } from '../category/CategoryDetail';
import { CategoryList } from '../category/CategoryList';
import ItemDetail from './ItemDetail';


export const ItemUI =()=> {

  const domain="items";
const header = [{"attr":"id","description":"ID"},{"attr":"code","description":"Code"},{"attr":"description","description":"Description"},
{"attr":"category.code","description":"Category"}];

  return (
    <div>
      <p>Item </p>
      <ItemDetail  domain={domain}  ></ItemDetail>
      <CategoryList domain={domain} header={header}></CategoryList>
    </div>
  );
}


