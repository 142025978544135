import React from "react";
import { useDispatch } from "react-redux";
import { Card, CardContent, Typography } from "@mui/material";
import { useGetSkusQuery } from "./state/ItemApiCall";

export const ItemGrid = ({ onSelect }) => {

  const { data, error, isLoading } = useGetSkusQuery()
  // const data =[]

  const dispatch = useDispatch()

  const itemSearch = (e) => {

    if (e.length > 4) {
      console.log(e)
    }
  }
  const onItemClicked = (e, data) => {
    onSelect(data)
  }

  let listItems = [];
  if (data && Array.isArray(data)) {
    listItems = data.map((data, index) =>
      <div onClick={e => onItemClicked(e, data)} className="item-list-item" key={index.toString()}>

        <Card sx={{ minWidth: 275 }}>
          <CardContent>
              <p style={{ overflow: 'hidden' }}>{data.id}</p>
              <p>{data.description}</p>
          </CardContent>
        </Card>

      </div>);
  };

  return <div>
    <div> item search
      <input onChange={(e) => itemSearch(e.target.value)} type="text" />
      <button onClick={(e) => itemSearch(e)}>search</button>
    </div>
    {/* <div>last selected {state.selected.id}</div> */}
    <div className="item-list">{listItems}</div>

  </div>

}