import axios from 'axios';
import config from '../../config';
import { fetchAll as pfetchAll, saveToDb, removeDoc } from '../../util/pouchdb';
const catconfig = config.isoffline;
const configv = config;
export const selectItem = (domain, item) => {

  return {
    type: `select_Item_${domain}`,
    data: item,
    domain
  };
};


export const fetchItemSuccuess = (domain, data) => {
  return {
    type: `FETCH_${domain}_SUCCESS`,
    data: data,
    domain
  };
};




export const fetchItemFailed = (domain, data) => {
  return {
    type: `FETCH_${domain}_FAILURE`,
    data: data,
    domain
  };
};



export const saveItemStarted = (domain) => {
  return {
    type: `SAVE_${domain}_Started`,
    domain
  };
};


export const saveItemCompleted = (domain, data) => {
  return {
    type: `SAVE_${domain}_Completed`,
    data: data,
    domain
  };
};


export const fetchItemStarted = (domain) => {
  return {
    type: `FETCH_${domain}_STARTED`,
    domain
  };
};

export const saveItemFailed = (domain, data) => {
  return {
    type: `SAVE_${domain}_Failed`,
    data: data,
    domain
  };
};



export const fetchItems = (domain, filter) => {

  return dispatch => {

    dispatch(fetchItemStarted(domain));

    if (catconfig) {
      if (!filter) {
        pfetchAll(domain).then(res => {
          //  const data =  res.rows.map(row => row.doc ) 
          dispatch(fetchItemSuccuess(domain, res));
        })
          .catch(err => {
            console.log(err)
            dispatch(fetchItemFailed(err.message));
          });
      } else {
        pfetchAll(domain, filter).then(res => {
          //  const data =  res.rows.map(row => row.doc ) 
          dispatch(fetchItemSuccuess(domain, res));
        })
          .catch(err => {
            console.log(err)
            dispatch(fetchItemFailed(err.message));
          });
      }
    } else {

      const xx = domain ? configv.api[domain].fetchItemsURL : '';
      axios
        .get(xx, {
          params: filter
        })
        .then(res => {
          dispatch(fetchItemSuccuess(domain, res.data));
        })
        .catch(err => {
          console.log(err)
          dispatch(fetchItemFailed(err.message));
        });
    }
  }
}

export const Get= (url,filter,callb)=>{
  return axios
  .get(url, {
    params: filter
  })
}


export const save = (domain, data, postSave, postSaveError) => {
  return dispatch => {
    if (catconfig) {
      dispatch(saveItemStarted(domain))
      saveToDb(domain, data).then(res => {
        dispatch(saveItemCompleted(domain));
        dispatch(fetchItems(domain));
        postSave(res);
      })
        .catch(err => {
          console.log(err)
          dispatch(saveItemCompleted(domain, err.message));
          postSaveError && postSaveError(err);
        });
    } else {
      let url = domain ? configv.api[domain].fetchItemsURL : '';
      let settings = data && data.id ? {
        method: 'put',
        url: url.concat("/").concat(data.id),
        data: data
      } : {
        method: 'post',
        url: url,
        data: data
      };


      axios(settings)
        .then(res => {
          dispatch(fetchItems(domain));
        })
        .catch(err => {
          console.log(err)
          dispatch(fetchItemFailed(err.message));
        });


    }
  }
}

export const deleteItem = (domain, item) => {
  return dispatch => {
    if (catconfig) {
      removeDoc(domain, item)
        .then(res => {
          // dispatch(saveItemCompleted(domain));
          dispatch(fetchItems(domain))
        })
        .catch(err => {
          console.log(err)
          // dispatch(saveItemCompleted(domain,err.message));
        });
    }
  }
}


export const CategoryActions = {
  domain: "",
  test: (xoo) => { console.log(xoo.domain) }
};
CategoryActions.fetchAll = () => {
  return fetchItems(CategoryActions.domain);
}



CategoryActions.fetchItems = fetchItems;