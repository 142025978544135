import React from 'react';
import './App.css';
import PosView from './app/sales/PosView';
import { Login } from './app/user/login';
// import * as itemjson from "./app/items/"; 
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  BrowserRouter as Router, Link, Route, Routes
} from "react-router-dom";

import { CategoryUI } from './app/category/CategoryUI';
import { ItemUI } from './app/items/ItemUI';
import PosScreen from './prototype/PosScreen';
import { POS } from './prototype/POS';


const itemconf = {
  "entityName": "Item",
  "fields": [
    { "name": "Name", "value": " Jawath " },
    { "name": "yes", "value": " No " }
  ]
}




function App() {


  const loginfun = (data) => {
    console.log(data);

  }

  return (
    <div >
      <Router>
        <AppBar position="static">
          <Toolbar>
            <Typography edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </Typography>

            <Typography aria-label="POS">
              <Link to="/pos">POS</Link>
            </Typography>

            <Typography color="inherit" aria-label="Item">
              <Link to="/items">items</Link>
            </Typography>

            <Typography color="inherit" aria-label="Category">
              <Link to="/categories">Categories</Link>
            </Typography>

            <Typography variant="h6" >
              <Link to="/login">Login</Link>
            </Typography>


            <Typography aria-label="ProtoType">
              <Link to="/prototype">ProtoType</Link>
            </Typography>

            <Typography aria-label="ProtoType">
              <Link to="/prepos">POS</Link>
            </Typography>


          </Toolbar>
        </AppBar>

        <div>


          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Routes>
            <Route path="/login" element={<Login />}>
            </Route>
            <Route path="/pos" element={<PosView />}>

            </Route>


            <Route path="/categories" element={<CategoryUI />}>

            </Route>
            <Route path="/items" element={<ItemUI />}>

            </Route>
            <Route path="/prototype" element={<PosScreen />}>

            </Route>
            
            <Route path="/prepos" element={<POS/>}>
            </Route>
          </Routes>
        </div>
      </Router>

    </div>
  );
}

export default App;
