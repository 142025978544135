import React from "react";
import { FormProvider, useForm } from "react-hook-form";


export function Form({ defaultValues, children, onSubmit }) {
  const methods = useForm({ mode: 'onChange', defaultValues });
  const { handleSubmit, formState, reset } = methods;
  const { isValid, isDirty, errors } = formState;

  const onSubmitAction = data => {
    onSubmit(data);
  }

  const newEntry = () => {
    reset();
    // dispatch(selectItem(domain))
    // reset()
  }


  const customReset = () => {
    reset()
  }

  return (
    <FormProvider {...methods}>

      <form onSubmit={handleSubmit(onSubmitAction)}>
        {React.Children.map(children, child => {
          return child.props.name
            ? React.createElement(child.type, {
              ...{
                ...child.props,

                key: child.props.name
              }
            })
            : child;
        })}

        <div className="inputfield">
          <input type="submit" className="btn" disabled={!isValid} />
        </div>
        <div className="inputfield">
          <input type="button" value="Reset" onClick={customReset} className="btn" />
        </div>
        <div className="inputfield">
          <input type="button" value="New" onClick={newEntry} className="btn" />
        </div>
        {/* <span>{errors && JSON.stringify(errors.code)}</span> */}
      </form>
    </FormProvider>

  );
}


