import React, { Component, useState, useEffect } from "react";
import { connect } from 'react-redux'
// import { Form } from "../form/Form";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux'
import { save } from '../../app/util/pouchdb'
import { CategoryDetail } from "../category/CategoryDetail";
import { fetchItems } from "../category/state/AdminActions";
import { extractDomainSelect } from "../category/state/util";
import Selector from "../category/Selector";
import TextField from "../component/TextField";
import Form from "../component/Form";
import { Input } from "../component/Input";
import { DetailForm } from "../component/DetailForm";


export const ItemDetail = ({ domain }) => {

  // const domain = "categories";
  const dispatch = useDispatch()
  const categorydomain = "categories";

  const categories = useSelector((state) => extractDomainSelect(state, categorydomain).listFetch)

  useEffect(() => {
    dispatch(fetchItems(categorydomain))
  }, []);


  const itemConfig = {

    code: {
      required: "Code is required",
      minLength: {
        value: 4,
        message: "Code must have at least 4 characters"
      }
    },
    description: { required: "Description is required" },
    category: {
      required: "category is required"
    }
  };

  const formUI = (state) => {
    return <>
      <label htmlFor="category">Cateogry :</label>
      <Selector categories={categories} name="category" />
      {/* <span>{errors.code}</span> */}
      <label htmlFor="code">Code :</label>
      <TextField name="code" validations={itemConfig.code} />
      <span>{state && state?.errors?.code?.message}</span>
      {/* <Input name="code" validations={itemConfig.code} /> */}
      <label htmlFor="description">Description : </label>
      <TextField name="description" validations={itemConfig.description}/>
      <span>{state && state?.errors?.description?.message}</span>

    </>
  }

  return <DetailForm domain={domain} formConfig={itemConfig} formUI={formUI}>

  </DetailForm>
}







export default ItemDetail;