import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { save, selectItem } from "../category/state/AdminActions";
import { extractDomainSelect } from "../category/state/util";
import "./detail.css";
import { Form } from "./Form";


export const DetailForm = ({ domain, children, formUI,defaultValues, formConfig }) => {


  const dispatch = useDispatch()
  const selectedItem = useSelector((state) => extractDomainSelect(state, domain).selectedItem);

  const methods = useForm({
    mode: "onChange",
    defaultValues: { ...selectedItem }
  });

  const reset = methods.reset;

  const onSubmit = (data) => {
    const obj = { ...selectedItem, ...data }
    dispatch(save(domain, obj))
  }

  useEffect(() => {
    reset({ ...selectedItem } || {})
  }, [reset, selectedItem]);
  return     <div className="wrapper">
    
      {/* <form onSubmit={methods.handleSubmit(onSubmit)}> */}
      {(formUI && formUI(methods.formState) || (children != null && <Form onSubmit={onSubmit} defaultValues={defaultValues}>{children}</Form>))}

      {/* </form> */}
    </div>
  
}