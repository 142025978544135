import React from 'react';
import { Controller , useFormContext } from 'react-hook-form';

const Selector = (props)=>  {
  const {categories,defaultValue,label ,name} = props;
  const { control } = useFormContext();
  return <Controller
      {...props}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={props =>
        <div>
        <select  value={props.value?.id}  ref={props.register} onChange={ele => props.onChange(categories.find(e => e.id === ele.target.value))}>
          {categories && categories.map(e => <option key={e.id} value={e.id}    
          >{e.code}</option>)}
        </select>
        </div>
      } 
    />
}

export default   Selector;
