import React, { Component, Fragment, useEffect } from "react";
import { v4 as UUID } from 'uuid';
import './ShoppingCart.css';
import { useSelector,useDispatch } from 'react-redux';
import { actions as CartActions} from '../sales/cart'


export const ShoppingCart =({item})=> {

    const cart = {}//useSelector(state=> state.cart);
    const dispatch = useDispatch();

    const bill=cart;
    // state={bill:{lines:[]}}

    const initi ={};
    initi.lines=[];
    // const  [bill, setBill] = useState(initi)
    
    const reduceOneItem=(e,index,item)=>{
        e.preventDefault();
        console.log(index)
        dispatch( CartActions.removeItemFromCart(item));
    }
    

    const removeItem=(e,index,item)=>{
        e.preventDefault();
        console.log(index)
        dispatch( CartActions.removeItemFromCart(item));
    }
    
    const onSelectItem=(e,item)=>{

        // setState({...state , selected:item});
        // props.onSelectLine(item);
    }



    const renderLineItems = () => {
        const list = Array.isArray(cart.items) ? cart.items: [];
        let no = 0;
        const lines =  list.map((value, index) => {
            return <tr className="cmpboarder" onClick={e=> onSelectItem(e,value)} key={index}>
                <td>{++no}</td>
                <td><span >{value.item && value.item.description}</span></td>
                <td>{value.price}</td>
                <td>{value.qty}</td>
                <td>{value.qty * value.price }</td>
                <td>
                    <button onClick={e=> removeItem(e,index,value)}>-1</button>
                    <button onClick={e=> removeItem(e,index,value)}>-</button>
                    {/* <button onClick={e=> addItem(e,index,value)}>+1</button> */}
                </td>
            </tr>
        });

        return <Fragment>
                {lines}
           
           </Fragment>


    }

        return <div className="card-box" id="card-box">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-10">
                                <select name="" id="customer" className="form-control">
                            </select>
                            </div>
                            <div className="col-md-2">
                                <button data-toggle="modal" data-target="#newCustomer" className="btn btn-success"><i className="fa fa-plus"></i></button>
                            </div>
                        </div>
                        <div className="input-group m-t-5">
                            <form action="" id="searchBarCode">
                                <input type="text" required id="skuCode" name="skuCode" className="form-control" placeholder="Scan barcode or type the number then hit enter" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                <input type="submit" />
                            </form>
                            <span className="input-group-addon" id="basic-addon2">
                                <i className="glyphicon glyphicon-ok"></i>
                            </span>
                        </div>
                    </div>
                    <div>

                    <div> 
                        {/* <button onClick={e=> addItem(e)}>+</button> <button>-</button> */}
                    </div>
        
                    <span>Line detail ..............</span>
           
                        <table className="shoping-cart" id="cartTable">
    
                            <thead>
                            <tr>
                                <th>#</th>
                                <th width="150px">Item</th>
                                <th width="170px">Price</th>
                                <th width="170px">Qty</th>
                                <th>LineAmount</th>
                                <th width="5px">
                                    <button  className="btn btn-danger btn-xs"><i className="fa fa-times"></i></button>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {renderLineItems()}
    
                            </tbody>
    
                        </table>
                    </div>
    
                    <hr/>
                   <div className="m-t-5">
                       <div className="row">
                           {/* <div className="col-md-3">{bill.lines.length}</div> */}
                           <div className="col-md-3">: <span id="total">0</span></div>
                           <div className="col-md-3">Price :</div>
                           <div className="col-md-3">: <span id="price">0.0</span></div>
                       </div>
                       <div className="row">
                           <div className="col-md-3">Discount</div>
                           <div className="col-md-3">
                               <input className="form-control" type="number" id="inputDiscount" />

                           </div>
                           <div className="col-md-3">Gross Price (inc <span id="taxInfo"></span>% Tax)</div>
                           <div className="col-md-3"><h3 id="gross_price">0.00</h3></div>
                       </div>
    
                   </div>
    
                    <div className="button-list pull-right"></div>
  
                      <button  type="button" className="btn btn-info waves-effect waves-light">
                          <i className="fa fa-print"></i> 
                      </button>
  
                        <button   type="button" className="btn btn-danger waves-effect waves-light">
                            <span className="btn-label"><i className="fa fa-ban"></i></span>Cancel
                        </button>
    
                        <button type="button" id="hold" className="btn btn-default waves-effect waves-light">
                            <span className="btn-label"><i className="fa fa-hand-paper-o"></i></span>Hold
                        </button>
    
                        <button type="button" id="payButton" className="btn btn-success waves-effect waves-light">
                            <span className="btn-label"><i className="fa fa-money"></i></span>Pay
                        </button>
                    </div>
                 
    }

    





export  const   dummyData=function(){
    const items = [];

    for(let x=0;x<5;x++){
        const xx = {'uid':UUID(), "item":{"code":" item "+x , "description":" desc "+x } , "price": 20+x , qty:x+1}
        items.push(xx);
    }
    return items;
}

