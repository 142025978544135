import { configureStore } from '@reduxjs/toolkit';
import { CategoriesApi } from "./category/state/CategoryApiCall";
import { SKUsApi } from './items/state/ItemApiCall';



export const store = configureStore({
    reducer: {
      // Add the generated reducer as a specific top-level slice
      [CategoriesApi.reducerPath]: CategoriesApi.reducer,
      [SKUsApi.reducerPath]: SKUsApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(CategoriesApi.middleware)
                            .concat(SKUsApi.middleware),
  })


export default store;