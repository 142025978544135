export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const LOAD_PRODUCT_TYPE = "LOAD_PRODUCT_TYPE";

export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";

export const UPDATE_DISCOUNT_ON_TOTAL = "UPDATE_DISCOUNT_ON_TOTAL";
export const UPDATE_DISCOUNT_ON_ITEMS = "UPDATE_DISCOUNT_ON_ITEMS";
export const UPDATE_TAX = "UPDATE_TAX";

export const INIT_TRANSACTION = "INIT_TRANSACTION";
export const CANCEL_TRANSACTION = "CANCEL_TRANSACTION";
